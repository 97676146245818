<template>
  <div id="edit-new-document" class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>{{ documentName }}</h1>
      <div class="btns-container">
        <VasionButton
          v-if="activeDocument.isTemplate && activeDocument.editTemplateId !== 0"
          id="save-button"
          name="save-button"
          classProp="primary"
          :isDisabled="!isAbleToSend"
          @vasionButtonClicked="getIpAndGeolocationThenSave()"
        >
          {{ useButtonText }}
        </VasionButton>

        <VasionButton
          v-if="!pairedDownView"
          id="save-button"
          name="save-button"
          :classProp="activeDocument.isTemplate && activeDocument.editTemplateId !== 0 ? &quot;secondary&quot; : &quot;primary&quot;"
          @vasionButtonClicked="activeDocument.isTemplate ? saveAsTemplate() : getIpAndGeolocationThenSave()"
        >
          {{ continueButtonText }}
        </VasionButton>
        <VasionButton
          v-if="!pairedDownView"
          id="back-button"
          class="last-btn"
          name="back-button"
          classProp="secondary"
          @vasionButtonClicked="back()"
        >
          BACK
        </VasionButton>
        <VasionButton
          v-if="pairedDownView"
          id="cancel-button"
          class="last-btn"
          name="cancel-button"
          classProp="secondary"
          @vasionButtonClicked="cancel()"
        >
          CANCEL
        </VasionButton>
      </div>
    </div>
    
    <div class="vap-page-content">
      <div v-if="activeDocument.isTemplate && !pairedDownView">
        <VasionButton
          id="general-tab"
          name="general-tab"
          :classProp="showPermissions ? '' : 'text-selected'"
          @vasionButtonClicked="showPermissions = false"
        >
          General
        </VasionButton>

        <VasionButton
          id="permission-tab"
          name="permission-tab"
          :classProp="!showPermissions ? '' : 'text-selected'"
          @vasionButtonClicked="showPermissions = true"
        >
          Permissions
        </VasionButton>
      </div>

      <!-- GENERAL TAB -->
      <div v-show="!showPermissions" id="main-section">
        <div id="edit-new-document-details">
          <div class="row">
            <VasionInput
              id="document-name"
              v-model="documentName"
              class="row-field"
              title="DOCUMENT NAME"
              inputType="top-white"
              name="document-name"
              placeholder="Enter Document Name..."
              :isInErrorState="fieldErrorValues.documentName"
              :required="true"
              :width="'396'"
              :isDisabled="bulkSendingTemplateMode"
              @focus="clearFieldErrors"
              @input="isDirty = true"
            />
            <VasionDropList
              v-show="!pairedDownView"
              v-slot="slotItem"
              v-model="attributeForm"
              class="row-field"
              :dataArray="attributeFormList"
              width="396"
              :title="`${$formsLabel.toUpperCase()}`"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              :isInErrorState="fieldErrorValues.object"
              @input="attributeFormInput"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionDropList
              v-show="isObjectSelected && !pairedDownView"
              v-slot="slotItem"
              v-model="attributeField"
              class="row-field"
              :dataArray="indexFieldList"
              width="396"
              title="Primary ID"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div v-show="!pairedDownView" class="row">
            <VasionDropList
              v-slot="slotItem"
              v-model="completedField"
              class="row-field"
              :dataArray="completedDestinationList"
              width="396"
              title="COMPLETED DESTINATION"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="name"
              :isInErrorState="fieldErrorValues.completedDestination"
              :required="true"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionDropList
              v-show="completedField.name === 'Workflow'"
              v-slot="slotItem"
              v-model="workflowField"
              class="row-field"
              :dataArray="workflowList"
              width="396"
              title="WORKFLOW"
              placeholder="..."
              type="plain-list"
              displayName="sName"
              valueName="iID"
              :isInErrorState="fieldErrorValues.workflowField"
              :required="true"
              @input="isDirty = true"
            >
              {{ slotItem.item.sName }}
            </VasionDropList>
            <div v-show="completedField.name === 'Storage'" class="vasion-flex">
              <VasionInput
                id="approved-destination"
                v-model="approvedDestinationFolder"
                class="row-field browse-input"
                title="APPROVED DESTINATION"
                inputType="top-white"
                name="approved-destination"
                placeholder="Select Folder..."
                :isInErrorState="fieldErrorValues.approvedDestinationFolder"
                :required="true"
                :width="'306'"
              />
              <VasionButton
                id="approved-destination-button"
                class="browse-button"
                :classProp="'primary'"
                @vasionButtonClicked="toggleBrowseFolderDialog('approved-destination'); clearFieldErrors()"
              >
                Browse
              </VasionButton>
            </div>
          </div>
          <div v-show="!pairedDownView" class="row">
            <div class="vasion-flex">
              <VasionInput
                id="rejected-destination"
                v-model="rejectedDestinationFolder"
                class="row-field browse-input"
                title="REJECTED DESTINATION"
                inputType="top-white"
                name="rejected-destination"
                placeholder="Select Folder..."
                :isInErrorState="fieldErrorValues.rejectedDestinationFolder"
                :required="true"
                :width="'306'"
              />
              <VasionButton
                id="rejected-destination-button"
                class="browse-button"
                :classProp="'primary'"
                @vasionButtonClicked="toggleBrowseFolderDialog('rejected-destination'); clearFieldErrors()"
              >
                Browse
              </VasionButton>
            </div>
          </div>
          <div class="row">
            <div id="lookup-preview">
              <span class="vasion-input-label-top" :class="{ 'error-state-label':fieldErrorValues.messageForSigners}">MESSAGE FOR SIGNERS *</span>
              <div id="message-content">
                <textarea
                  id="message-textarea"
                  ref="message"
                  v-model="messageForSigners"
                  rows="5"
                  class="vasion-textarea"
                  name="message-textarea"
                  :class="{ 'error-state-container':fieldErrorValues.messageForSigners }"
                  :disabled="bulkSendingTemplateMode"
                  @focus="clearFieldErrors"
                  @input="isDirty = true"
                />
              </div>
            </div>
          </div>
          <div v-show="!pairedDownView" class="row">
            <VasionDropList
              v-slot="slotItem"
              v-model="selectedDocLinkExpiration"
              class="row-field"
              :dataArray="linkExpirationOptions"
              width="175"
              title="Document Link Expiration"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="value"
              :isInErrorState="fieldErrorValues.selectedDocLinkExpiration"
              :required="true"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionCheckbox
              id="include-link"
              name="include-link"
              class="export-checkbox align-with-inputs paddingRight"
              :checked="includeLink"
              @change="toggleCheckbox('includeLink')"
            >
              Include Document Link
            </VasionCheckbox>
            <VasionCheckbox
              id="signed-copy"
              name="signed-copy"
              class="export-checkbox align-with-inputs"
              :checked="sendCopyToAllSigners"
              @change="toggleCheckbox('sendCopyToAllSigners')"
            >
              Send Signed Copy to all Parties
            </VasionCheckbox>
          </div>
          <div id="signing-options" class="collapsable-section">
            <h2 class="subheader">
              Signing Options
            </h2>
            <VasionButton
              name="collapse-section"
              class="collapse-section"
              :icon="showSigningOptions ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
              title="Collapse Section"
              @vasionButtonClicked="toggleCollapsableSection('signing-options')"
            />
            <div v-show="showSigningOptions">
              <div class="bulk-upload-settings">
                <div class="row">
                  <VasionInput
                    id="sender-name"
                    v-model="senderName"
                    class="row-field"
                    title="SENDER NAME"
                    inputType="top-white"
                    name="sender-name"
                    :width="'424'"
                    :isDisabled="bulkSendingTemplateMode"
                  />
                  <VasionInput
                    id="sender-email"
                    v-model="senderEmail"
                    class="row-field"
                    title="SENDER EMAIL"
                    inputType="top-white"
                    name="sender-email"
                    :width="'424'"
                    :isInErrorState="fieldErrorValues.signingOptions"
                    :isDisabled="bulkSendingTemplateMode"
                  />
                </div>
              </div>
              <div v-show="!pairedDownView" class="row align-with-inputs">
                <md-radio id="signing-radio" v-model="signingType" value="signingOrder">
                  Assign Signing Order
                </md-radio>
                <md-radio id="routing-radio" v-model="signingType" value="simultaneousRouting">
                  Parallel Routing
                </md-radio>
                <VasionCheckbox
                  v-if="activeDocument.isTemplate"
                  id="enable-bulk-sending"
                  name="enable-bulk-sending"
                  class="export-checkbox align-with-inputs"
                  :checked="bulkSendingEnabled"
                  @change="onEnableBulkSendingChange"
                >
                  Enable Bulk Sending
                </VasionCheckbox>
              </div>
              <div v-show="bulkSendingEnabled" class="bulk-upload-settings">
                <div class="row">
                  <div class="vasion-flex">
                    <VasionInput
                      id="file-delimiter"
                      v-model="fileDelimiter"
                      class="row-field"
                      title="FILE DELIMITER"
                      inputType="top-white"
                      name="file-delimiter"
                      :width="'116'"
                      :isDisabled="bulkSendingTemplateMode"
                    />
                    <VasionInput
                      id="csv-template"
                      v-model="uploadedCSVFileName"
                      class="row-field browse-input"
                      title="CSV TEMPLATE"
                      inputType="top-white"
                      name="csv-template"
                      placeholder="Upload File"
                      :width="'306'"
                      isDisabled
                    />
                    <VasionButton
                      id="csv-template-button"
                      class="browse-button"
                      :classProp="'primary'"
                      @vasionButtonClicked="toggleUploadDialog"
                    >
                      Choose File
                    </VasionButton>
                  </div>
        
                  <VasionDropList
                    v-slot="slotItem"
                    v-model="selectedDocTemplate"
                    class="row-field"
                    :dataArray="docTemplates"
                    width="780"
                    title="DOCUMENT TEMPLATE"
                    placeholder="Select Document..."
                    type="plain-list"
                    displayName="name"
                    valueName="id"
                    :showIfEmpty="false"
                    :isDisabled="bulkSendingTemplateMode"
                    @input="isDirty = true"
                  >
                    {{ slotItem.item.name }}
                  </VasionDropList>
                </div>
                <div class="row">
                  <VasionButton
                    id="configure-mapping-button"
                    v-show="!bulkSendingTemplateMode"
                    :classProp="'primary'"
                    :isDisabled="!isObjectSelected || mappingConfigurationColumnsArray.length === 0"
                    @vasionButtonClicked="openConfigureMapping"
                  >
                    Configure Mapping
                  </VasionButton>
                </div>
              </div>
              <div class="row">
                <div
                  v-if="!activeDocument.isTemplate"
                  class="vasion-html-table-default paddingRight w100"
                >
                  <VasionTable
                    v-if="tableData.Columns"
                    :headerColumns="tableData.Columns.Values"
                    :tableRows="tableData.Rows.Values"
                    :hideColumns="signingType === 'signingOrder' ? hiddenColumnsSigningOrder : hiddenColumnsParallelRouting"
                    :filterByColumnDefault="1"
                    :blankUsers="blankUsers"
                    :iconIndeces="arrowIcons"
                    :dropDownIndeces="dropDownIndeces"
                    :dropDownValues="dropDownRoleValues"
                    @vasion-icon-click="iconClick"
                    @vasion-delete-icon-click="deleteIconClick"
                    @cell-change="cellChangedSigningOrder"
                  />
                  <VasionButton
                    :classProp="'secondary-grey'"
                    @vasionButtonClicked="addParticipant()"
                  >
                    Add Participant
                  </VasionButton>
                </div>
                <div v-if="activeDocument.isTemplate" class="grid-div vasion-html-table-default paddingRight w100">
                  <TemplateMappingTable
                    v-if="templateTableData"
                    ref="TemplateMappingTable"
                    :allowFieldReordering="signingType === 'signingOrder'"
                    :roleArray="dropDownRoleArray"
                    :tableData="templateTableData"
                    :signerFields="signerFields"
                    :signerNameFields="signerNameFields" 
                    :isDisabled="bulkSendingTemplateMode"                 
                    @checkbox-clicked="checkboxClicked"
                    @move-rows="moveTemplateRows"
                    @delete-row="deleteParticipantTemplate"
                    @uncheck-when-disabled="uncheckWhenDisabled"
                    @readyToSend="setTemplateReadyToSend()"
                    @role-droplist-change="updateRoleFromDropList"
                  />
                  <VasionButton
                    v-if="!bulkSendingTemplateMode"
                    :classProp="'secondary-grey'"
                    @vasionButtonClicked="addParticipantTemplate()"
                  >
                    Add Participant
                  </VasionButton>
                </div>
              </div>
              <div class="row">
                <VasionCheckbox
                  v-show="!pairedDownView"
                  id="assign-timestamp"
                  name="assign-timestamp"
                  class="export-checkbox align-with-inputs"
                  :checked="applySignatureTimestamp"
                  :isDisabled="bulkSendingTemplateMode"
                  @change="toggleCheckbox('applySignatureTimestamp')"
                >
                  Apply Timestamp to All Signatures
                </VasionCheckbox>
              </div>
              <div>
                <span v-show="!bulkSendingTemplateMode" class="vasion-page-subheader-dark">Anyone else need to receive a copy? Add CC Recipients (optional)</span>
                <div
                  v-for="(ccEmail, i) in CCEmails"
                  :key="i"
                  class="row"
                >
                  <VasionInput
                    id="email-address"
                    v-model="ccEmail.value"
                    class="row-field"
                    title="EMAIL ADDRESS"
                    inputType="top-white"
                    name="email-address"
                    :specialType="'email'"
                    :placeholder="'Enter Email...'"
                    :isInErrorState="fieldErrorValues.ccEmails"
                    :isDisabled="bulkSendingTemplateMode"
                    @input="isDirty = true"
                  />
                  <VasionButton
                    title="Remove Field"
                    :isDense="true"
                    :icon="'delete'"
                    @vasionButtonClicked="deleteCCEmail(i)"
                  />
                </div>
                <VasionButton
                  v-if="!bulkSendingTemplateMode"
                  id="addCCRecipient"
                  :classProp="'secondary-grey'"
                  class="row"
                  @vasionButtonClicked="addCCRecipient"
                >
                  Add CC Recipient
                </VasionButton>
              </div>
            </div>
          </div>
          <div
            v-show="!pairedDownView"
            id="email-options"
            class="collapsable-section"
            :class="{ 'error-container-state': fieldErrorValues.messageForSigners }"
          >
            <h2 class="subheader">
              Email Options
            </h2>

            <VasionButton
              name="collapse-section"
              class="collapse-section"
              :icon="showEmailOptions ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
              title="Collapse Section"
              @vasionButtonClicked="toggleCollapsableSection('email-options')"
            />
            <div v-if="showEmailOptions">
              <div class="row">
                <VasionDropList
                  v-slot="slotItem"
                  v-model="initiatorEmailOption"
                  class="row-field"
                  :dataArray="initiatorEmailOptionList"
                  width="396"
                  title="INITIATOR EMAIL OPTION"
                  placeholder="Select Initiator Email Option..."
                  type="plain-list"
                  displayName="name"
                  valueName="value"
                  :isInErrorState="fieldErrorValues.messageForSigners"
                  :isDisabled="bulkSendingTemplateMode"
                  @input="clearFieldErrors(); isDirty = true"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </div>
              <div class="row">
                <VasionDropList
                  v-slot="slotItem"
                  v-model="newDocumentTemplate"
                  class="row-field"
                  :dataArray="emailTemplateList"
                  width="396"
                  title="NEW DOCUMENT TEMPLATE"
                  placeholder="Select Email Template..."
                  type="plain-list"
                  displayName="name"
                  valueName="value"
                  :isInErrorState="fieldErrorValues.messageForSigners"
                  :isDisabled="bulkSendingTemplateMode"
                  @input="clearFieldErrors(); isDirty = true"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </div>
              <div class="row">
                <VasionDropList
                  v-slot="slotItem"
                  v-model="completedTemplate"
                  class="row-field"
                  :dataArray="emailTemplateList"
                  width="396"
                  title="COMPLETED TEMPLATE"
                  placeholder="Select Email Template..."
                  type="plain-list"
                  displayName="name"
                  valueName="value"
                  :isDisabled="bulkSendingTemplateMode"
                  @input="isDirty = true"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </div>
              <div class="row">
                <VasionDropList
                  v-slot="slotItem"
                  v-model="rejectedTemplate"
                  class="row-field"
                  :dataArray="emailTemplateList"
                  width="396"
                  title="REJECTED TEMPLATE"
                  placeholder="Select Email Template..."
                  type="plain-list"
                  displayName="name"
                  valueName="value"
                  :isDisabled="bulkSendingTemplateMode"
                  @input="isDirty = true"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </div>
              <div class="row">
                <VasionDropList
                  v-slot="slotItem"
                  v-model="recalledTemplate"
                  class="row-field"
                  :dataArray="emailTemplateList"
                  width="396"
                  title="RECALLED TEMPLATE"
                  placeholder="Select Email Template..."
                  type="plain-list"
                  displayName="name"
                  valueName="value"
                  :isDisabled="bulkSendingTemplateMode"
                  @input="isDirty = true"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PERMISSIONS TAB -->
      <div v-if="showPermissions" id="permissions">
        <div>
          <VasionListSelection
            :available-fields.sync="availableUsers"
            :selected-fields.sync="assignedUsers"
            :allow-field-ordering="false"
            :available-label-text="'All Users'"
            :selected-label-text="'Permitted Users'"
            :unique-id="'permissions-users'"
            @update:availableFields="markDirty"
            @update:selectedFields="markDirty"
            @update:addAll="markDirty"
            @update:removeAll="markDirty"
          />
        </div>
        <div class="separator-div" />
        <div>
          <VasionListSelection
            :available-fields.sync="availableGroups"
            :selected-fields.sync="assignedGroups"
            :allow-field-ordering="false"
            :available-label-text="'All Groups'"
            :selected-label-text="'Permitted Groups'"
            :unique-id="'permissions-groups'"
            @update:availableFields="markDirty"
            @update:selectedFields="markDirty"
            @update:addAll="markDirty"
            @update:removeAll="markDirty"
          />
        </div>
      </div>
    </div>
    <md-dialog id="browse-folders-dialog" :md-active.sync="showBrowseFoldersDialog" :md-click-outside-to-close="false">
      <div class="folder-dialog-browse">
        <VasionFolders @itemSelected="handleFolderSelected" />
      </div>
      <div class="align-right">
        <VasionButton
          id="browse-cancel"
          classProp="secondary"
          @vasionButtonClicked="toggleBrowseFolderDialog()"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="browse-ok"
          classProp="primary"
          :isDisabled="!folderSelected"
          @vasionButtonClicked="folderDialogOK()"
        >
          OK
        </VasionButton>
      </div>
    </md-dialog>

    <VasionFileUploadDialog
      :serverOptions="serverOptions"
      :show.sync="showUploadDialog"
      :acceptedFileTypes="['.csv']"
      :filePondLabel="filePondLabel"
      @allFilesUploaded="toggleUploadDialog"
    />

    <DocumentObjectModal
      :show="showSendAttributeFormModal"
      :confirmButtonText="'Send for Signature'"
      :disableSaveAnnotations="true"
      :formId="currentFormId"
      :title="documentName"
      :documentFilePath="activeDocument.filePath"
      @closeDialogClicked="showSendAttributeFormModal = false"
      @confirmButtonClicked="saveFromModal"
      @rejectButtonClicked="showSendAttributeFormModal = false"
    />

    <VasionGeneralModal
      id="configure-mapping-modal"
      modalType="slot"
      :sync="showConfigureMapping"
      confirmButtonText="Save"
      rejectButtonText="Cancel"
      modalTitle="Mapping Configuration"
      showTopRightCloseButton
      @confirmButtonClick="setMappingConfiguration"
      @noButtonClick="showConfigureMapping = false"
    >
      <div id="configure-mapping-container">
        <table id="mapping-config-table">
          <tr>
            <th class="first">
              Object Field
            </th>
            <th class="second">
              Map To
            </th>
          </tr>
          <tr v-for="row in configTableRows" :key="row.name">
            <td class="first">
              {{ row.title }}
            </td>
            <td class="second">
              <VasionDropList
                v-slot="slotItem"
                v-model="mappingConfiguration[row.name]"
                class="row-field"
                :dataArray="row.columns"
                width="396"
                placeholder="Select Column..."
                type="plain-list"
                displayName="name"
                valueName="name"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </td>
          </tr>
        </table>
      </div>
    </VasionGeneralModal>


    <VasionGeneralModal
      id="failed-records-modal"
      :modalType="'slot'"
      :sync="showBulkSendFailureDetails"
      :modalTitle="'Failed Records'"
      confirmButtonText="Continue Sending"
      rejectButtonText="Cancel"
      @confirmButtonClick="doBulkSendFromFailureDetails"
      @noButtonClick="showBulkSendFailureDetails = !showBulkSendFailureDetails"
    >
      <span />
      <div id="failed-records-div">
        <VasionTable :headerColumns="bulkSendFailureDetails.tableHeaders" :tableRows="bulkSendFailureDetails.tableRows" />
      </div>
    </VasionGeneralModal>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionGeneralModal
      id="show-bulk-sending-config-warning"
      :modalType="'slot'"
      :sync="showBulkSendingConfigWarning"
      confirmButtonText="OK"
      :showRejectButton="false"
      @confirmButtonClick="showBulkSendingConfigWarning = false"
      @noButtonClick="showBulkSendingConfigWarning = false"
    >
      <p>An object must be selected in order to enable bulk sending.  Please select an object.</p>
    </VasionGeneralModal>

    <VasionSnackbar
      id="save-result-snack"
      :showRawHtml="showRawHtml" 
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
      :linkText="snackbarLinkText"
      @linkClicked="snackbarlinkClicked()"
    />
  </div>
</template>

<script>
import publicIp from 'public-ip';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { toBase64 } from '@/store/helperModules/storage.module'
import TemplateMappingTable from '@/views/digitalSignature/TemplateMappingTable.vue'
import TheReportVue from '../reporting/TheReport.vue';
import DocumentObjectModal from '@/components/document/DocumentObjectModal.vue';

export default {
  name: 'SignatureEditDocument',
  components: {
    DocumentObjectModal,
    Loading,
    TemplateMappingTable,
  },
  beforeRouteLeave(to, from, next) {
    if ((this.isDirty || this.isModalFormDirty) && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      ROLE_INDEX: 0,
      SIGNER_NAME_INDEX: 1,
      EMAIL_ADDRESS_INDEX: 2,
      SIGNER_FIELD_BOOL_INDEX: 3,
      EXTERNAL_USER_BOOL_INDEX: 4,
      EXTERNAL_USER_NAME_INDEX: 5,
      EXTERNAL_USER_EMAIL_INDEX: 6,
      USER_IS_BLANK: 7,
      applySignatureTimestamp: false,
      approvedDestinationFolder: '',
      approvedDestinationFolderId: 0,
      arrowIcons: [4, 5],
      assignedGroups: [],
      assignedUsers: [],
      assignSigningOrder: false,
      attributeField: '',
      attributeForm: { name: '[None]', value: null },
      availableGroups: [],
      availableUsers: [],
      blankUsers: [],
      bulkSendingEnabled: false,
      CCEmails: [],
      columns: {
        UserIndex: [
          '',
          'FullName',
          'Email',
          '',
          '',
          '',
          '',
        ],
        Values: [
          '_UserID_',
          'Name',
          'Email Address',
          'Role',
          '_VasionIconButton_',
          '_VasionIconButton_',
          '_VasionDeleteIconButton_',
        ],
        ValueType: 'String',
      },
      completedDestinationList: [
        { name: 'Storage' },
        { name: 'Workflow' },
      ],
      completedField: '',
      completedTemplate: '',
      configTableRows: [],
      currentPageNumber: 1,
      documentName: '',
      dropDownIndeces: [3],
      dropDownRoleValues: [
        { name: 'Viewer', },
        { name: 'Reviewer' }
      ],
      dropDownRoleArray: [
        {value: 'Viewer'},
        {value: 'Reviewer'},
      ],
      fieldErrorValues: {
        approvedDestinationFolder: false,
        completedDestination: false,
        documentName: false,
        documentNameTrimmed: false,
        messageForSigners: false,
        object: false,
        rejectedDestinationFolder: false,
        selectedDocLinkExpiration: false,
        workflowField: false,
      },
      fieldValuesFromModal: {},
      fileBrowseField: '',
      fileDelimiter: '',
      filePondLabel: `<span class="filepond-drag">Drag CSV File Here</span>
                      <br/><br/>
                      <span class="filepond-label-action">or click to upload your CSV File</span>`,
      folderSelected: false,
      formInputs: [],
      geolocation: '',
      hiddenColumnsSigningOrder: ['_UserID_'],
      hiddenColumnsParallelRouting: ['_UserID_', '_VasionIconButton_'],
      includeLink: true,
      initiatorEmailOption: { value: 1 },
      initiatorEmailOptionList: [
        {
          name: 'Send Initiator Email Upon Workflow Completion',
          value: 1,
        },
        {
          name: 'Send Initiator Email Upon Step Completion',
          value: 2,
        },
        {
          name: 'Send Initiator Email Upon Action Taken',
          value: 0,
        },
        {
          name: 'No Email Notifications',
          value: 3,
        },
      ],
      ipAddress: '',
      isBulkSendUpload: false,
      isDirty: false,
      isDocumentLoadedx: false,
      isLoading: false,
      isModalFormDirty: false,
      isModalFormValid: false,
      linkExpirationOptions: [
        {
          name: '1 Day',
          value: 1,
        },
        {
          name: '1 Week',
          value: 2,
        },
        {
          name: '2 Weeks',
          value: 3,
        },
        {
          name: '1 Month',
          value: 4,
        },
        {
          name: '3 Months',
          value: 5,
        },
        {
          name: '6 Months',
          value: 6,
        },
        {
          name: '1 Year',
          value: 7,
        }
      ],
      loaderBackgroundColor,
      loaderColor,
      localUserList: [],
      mappingConfiguration: {},
      mappingConfigurationColumnsArray: [],
      messageForSigners: '',
      newDocumentTemplate: '',
      originalRoles: [],
      recalledTemplate: '',
      rejectedDestinationFolder: '',
      rejectedDestinationFolderId: 0,
      rejectedTemplate: '',
      sendCopyToAllSigners: true,
      selectedDocLinkExpiration: {
        name: '1 Week',
        value: 2,
      },
      selectedDocTemplate: {},
      senderEmail: '',
      senderName: '',
      serverOptions: {
        process: this.processHandler,
      },
      showBulkSendFailureDetails: false,
      showBulkSendingConfigWarning: false,
      showConfigureMapping: false,
      showConfigureSigners: true,
      showLeaveDialog: false,
      showPermissions: false,
      showSendAttributeFormModal: false,
      showRawHtml: false,
      showSnackbar: false,
      showUploadDialog: false,
      signersColumns: {
        Values: [
          '_UserID_',
          'Role',
          'Signer Name',
          'Email Address',
        ],
        ValueType: 'String',
      },
      signersHiddenColumns: ['_userID_'],
      signersTableData: {},
      signingType: 'simultaneousRouting',
      snackbarImage: false,
      snackbarLinkText: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      showBrowseFoldersDialog: false,
      showEmailOptions: false,
      showSigningOptions: true,
      tableData: {},
      templateTableData: {
        Columns: { Values: ['Role', 'Signer Name', 'Email Address', 'Signer Field', 'External User', ''] },
        Rows: [],
      },
      templateTableMappingReadyToSend: false,
      uploadedCSVFileName: '',
      uploadedCSVFileString: '',
      userList: [],
      workflowField: '',
      workflowList: [],
    }
  },
  computed: {
    activeDocument() { return this.$store.state.digitalSignature.activeDocument },
    addedUserList() { return this.$store.state.digitalSignature.addedUsersList },
    attributeFormList() {
      const objectList = this.$store.state?.attributeForm?.forms?.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
      return objectList
    },
    bulkSendFailureDetails() { return this.$store.state.digitalSignature.bulkSendFailureDetails },
    bulkSendingTemplateMode() { return this.bulkSendingEnabled && this.pairedDownView },
    continueButtonText() { return this.activeDocument.isTemplate ? 'Save Template' : 'Send' },
    currentFormId() { return !this.attributeForm?.value ? 0 : this.attributeForm.value },
    docTemplates() { return this.$store.getters['forms/availableTemplates'] },
    emailTemplateList() {
      return this.$store.state?.common?.emailTemplates?.map((t) => {
        return {
          name: t.sName,
          value: t.iID,
        }
      })
    },
    groups() { return this.$store.state.common.groups },
    indexFieldList() { return this.$store.state.common.indexFields },
    isAbleToSend() {
      if (this.bulkSendingEnabled) {
        return this.uploadedCSVFileString !== ''
      }
      if (this.activeDocument.isTemplate) {
        return this.templateTableMappingReadyToSend
      }

      return this.activeDocument.assignedUserSignatureList?.length > 0
    },
    isDocumentLoaded() { return this.totalPages !== '' && this.uploadedFilePath !== '' },
    isObjectSelected() { return this.attributeForm && this.attributeForm.value },
    pairedDownView() { return this.$route.params.pairedDownView },
    selectedFolder() { return this.$store.state.common.selectedFolder },
    separateCopyForSigners() { return this.$store.state.digitalSignature.separateCopyForSigners },
    signerFields() { return this.$store.state.common.indexFields.filter(field => { return field.type === 12 }) },
    signerNameFields() { return this.$store.state.common.indexFields.filter(field => { return field.type === 1 || field.type === 2 }) },
    twoFactorAuth() { return this.$store.state.digitalSignature.twoFactorAuth },
    uploadedFilePath() { return this.activeDocument.filePath },
    useButtonText() { return this.$route.params.pairedDownView ? 'Send' : 'Use Template' },
    users() { return this.$store.state.common.users },
  },
  async created() {
    const promise1 = this.$store.dispatch('attributeForm/getForms')
    const promise2 = this.$store.dispatch('common/getEmailTemplates')
    const promise3 = this.$store.dispatch('common/getUsers')
    const promise4 = this.$store.dispatch('common/getGroups')
    const promise5 = this.$store.dispatch('workflow/getWorkflowMenuItems')
    const promise6 = this.$store.dispatch('digitalSignature/getSignatureDocumentTemplates')
    const promises = await Promise.all([promise1, promise2, promise3, promise4, promise5, promise6])
    await this.$store.dispatch('attributeForm/getFormsForUser')

    this.workflowList = promises[4]
    this.userList = this.addedUserList
    this.refreshTable()
    this.loadUsersAndGroups()
    await this.loadSavedTemplate()

    if (this.isObjectSelected) await this.$store.dispatch('forms/getCreateTempDocsByFormID', this.attributeForm?.value)

    if (this.mappingConfiguration) {
      let tempArray = []
      Object.entries(this.mappingConfiguration).forEach(val => {
        if (!tempArray.includes(val[1])) this.mappingConfigurationColumnsArray.push({ name: val[1] })
        tempArray.push(val[1])
      })
    }

    if (this.bulkSendingTemplateMode) {
      this.toggleBulkSendUploadDialog()
    }

    if (!this.activeDocument.isTemplate) {
      this.hiddenColumnsSigningOrder.push('Role')
      this.hiddenColumnsParallelRouting.push('Role')
    }
  },
  methods: {
    addParticipant() {
      this.userList.push({
        Active: false,
        Admin: false,
        Email: "",
        FirstName: "",
        FullName: "",
        LastName: "",
        MI: null,
        ResultMessage: null,
        ResultStatus: 0,
        UserId: -1,
        Username: "",
        disableButton: true
      })

      this.activeDocument?.assignedUserSignatureList.push({
        objectId: -1,
        pageNumber: 0,
        subType: "",
        userId: -1,
      })

      this.refreshTable()

      this.blankUsers.push(this.tableData.Rows.Values.length)
    },
    addParticipantTemplate () {
      this.templateTableData.Rows.push(['', '', '', false, true, '', '', true])
    },
    addCCRecipient () {
      this.CCEmails.push({ value: '' });
    },
    async attributeFormInput(form) {
      this.getFormInputs(form)
      this.mappingConfiguration = {}
      if(this.uploadedCSVFileName?.length === 0){
        this.mappingConfigurationColumnsArray = []
      }
      this.selectedDocTemplate = {}
      await this.$store.dispatch('forms/getCreateTempDocsByFormID', form?.value)
    },
    async back() {
      const payload = {
        documentName: this.documentName,
        attributeForm: this.attributeForm,
        attributeField: this.attributeField,
        completedField: this.completedField,
        approvedDestinationFolder: this.approvedDestinationFolder,
        approvedDestinationFolderId: this.approvedDestinationFolderId,
        rejectedDestinationFolder: this.rejectedDestinationFolder,
        rejectedDestinationFolderId: this.rejectedDestinationFolderId,
        messageForSigners: this.messageForSigners,
        includeLink: this.includeLink,
        signingType: this.signingType,
        applySignatureTimestamp: this.applySignatureTimestamp,
        newDocumentTemplate: this.newDocumentTemplate,
        completedTemplate: this.completedTemplate,
        sendCopyToAllSigners: this.sendCopyToAllSigners,
        rejectedTemplate: this.rejectedTemplate,
        recalledTemplate: this.recalledTemplate,
        availableUsers: this.availableUsers,
        assignedUsers: this.assignedUsers,
        availableGroups: this.availableGroups,
        assignedGroups: this.assignedGroups,
      }
      await this.$store.dispatch('digitalSignature/setSignatureTempData', payload)
      if (!this.activeDocument.isTemplate) await this.$store.dispatch('digitalSignature/setKeepDataOnReturn', true)
      
      this.$router.push({ name: 'NewDocument' })
    },
    buildSaveTemplatePayload() {
      let obj = this.$refs.TemplateMappingTable.formatForSave()
      for (let i = 0; i < obj.users.length; i++) {
        //1 is where the user information lives for each entrant
        this.templateTableData.Rows[obj.indeces[i]][1] = obj.users[i]
      }
      let orderedSigners = []
      if (this.templateTableData?.Rows?.length) { // Build out the orderedSigners array from the templateTableData
        this.templateTableData.Rows.map((v, index) => {
          return orderedSigners.push({ id: String(v[0]), order: this.signingType === 'signingOrder' ? index : 0 })
        })
      }
      let payload = {
        sendToCcRecipients: this.cleanedUpCCEmails(),
        signatureDocumentTemplateID: this.documentID,
        filePath: this.activeDocument.filePath,
        formID: (this.isObjectSelected ? this.attributeForm?.value : null),
        templateName: this.documentName,
        approvedFolderID: this.completedField?.name === 'Storage' ? this.approvedDestinationFolderId : null,
        approvedFolderName: this.approvedDestinationFolder,
        wfid: this.completedField?.name === 'Workflow' ? this.workflowField.iID : null,
        rejectedFolderID: this.rejectedDestinationFolderId,
        rejectedFolderName: this.rejectedDestinationFolder,
        messageForSigners: this.messageForSigners,
        includeDocumentLinkToSigner: this.includeLink,
        newDocumentEmailTemplateID: this.newDocumentTemplate?.value ? this.newDocumentTemplate.value : null,
        completedEmailTemplateID: this.completedTemplate?.value ? this.completedTemplate.value : null,
        rejectedEmailTemplateID: this.rejectedTemplate?.value ? this.rejectedTemplate.value : null,
        recalledEmailTemplateID: this.recalledTemplate?.value ? this.recalledTemplate.value : null,
        applySignatureTimestamp: this.applySignatureTimestamp,
        sendCompletedCopyToAllSigners: this.sendCopyToAllSigners,
        primaryFieldName: this.attributeField !== '' && this.attributeField?.value ? this.attributeField.value : '',
        accessUserIDs: this.assignedUsers.map(e => e?.value).filter(e => e != null),
        accessGroupIDs: this.assignedGroups.map(e => e?.value).filter(e => e != null),
        bulkSenderName: this.senderName,
        bulkSenderEmail: this.senderEmail,
        isBulkSending: this.bulkSendingEnabled,
        bulkSendMapping: this.mappingConfiguration,
        bulkSendDelimiter: this.fileDelimiter,
        bulkSendCreateDocTemplateID: this.selectedDocTemplate.id ? this.selectedDocTemplate.id : null,
        initiatorEmailOption: this.initiatorEmailOption?.name ? this.initiatorEmailOption.value : 1,
        createUsers: this.addedUserList?.filter(user => { return !Number(user?.UserId) })?.map(user => {
          return {
            firstName: user?.FirstName ? user.FirstName : '',
            lastName: user?.LastName ? user.LastName : '',
            emailAddress: user?.Email ? user.Email : '',
            phoneNumber: user?.PhoneNumber ? user.PhoneNumber : ''
          }
        }),
        orderedSignerUserIDs: orderedSigners?.filter(x => {
          const val = this.activeDocument.assignedUserSignatureList?.findIndex(a => {
            return a.userId === x.id
          })
          return val > -1
        }),
        roles: this.pairedDownView ? this.originalRoles : this.templateTableData.Rows.map((role, i) => {
          return {
            sigDocTemplateRoleName: role[0],
            userID: (role[4] || role[3]) ? null : role[1]?.UserId,
            signatureFieldName: role[3] ? role[2]?.value : null,
            signerNameFieldName: role[3] ? role[1]?.value : null,
            externalUserFirstAndLastName: role[4] ? role[5] : null,
            externalUserEmail: role[4] ? role[6] : null,
            roleOrder: this.signingType === 'signingOrder' ? i : 0,
          }
        }),
        zones: this.activeDocument.assignedZonesSignatureList.map(role => {
          return {
            annotationID: role.objectId,
            sigDocTemplateRoleName: role.sigDocTemplateRoleName,
            pageNumber: role.pageNumber,
            bottomAlign: role.bottomAlign,
            isRequired: role.isRequired,
          }
        }),
        linkExpirationType: this.selectedDocLinkExpiration?.value,
      }
      payload = this.checkParallelandViewersAndReviewers(payload)
      return payload
    },
    buildStartSignaturePayload() {
      const orderedSigners = []

      if (this.activeDocument && this.activeDocument.assignedUserSignatureList) {
        this.activeDocument.assignedUserSignatureList.forEach(userSignature => {
          userSignature.userId = String(userSignature.userId)
        })
      }

      if (this.tableData?.Rows?.Values.length) { // Build out the orderedSigners array from the table values
        this.tableData.Rows.Values.map((v, index) => {
          return orderedSigners.push({ id: String(v.Values[0]), order: this.signingType === 'signingOrder' ? index : 0 })
        })
      }
      let payload = {
        sendToCcRecipients: this.cleanedUpCCEmails(),
        filePath: this.activeDocument.filePath,
        deleteFileOnSuccess: true,
        formID: (this.isObjectSelected ? this.attributeForm?.value : null),
        fieldValues: this.fieldValuesFromModal,
        documentDisplayName: this.documentName,
        approvedFolderID: this.completedField?.name === 'Storage' ? this.approvedDestinationFolderId : null,
        wfid: this.completedField?.name === 'Workflow' ? this.workflowField.iID : null,
        rejectedFolderID: this.rejectedDestinationFolderId,
        messageForSigners: this.messageForSigners,
        includeDocumentLinkToSigner: this.includeLink,
        newDocumentEmailTemplateID: this.newDocumentTemplate?.value ? this.newDocumentTemplate.value : null,
        completedEmailTemplateID: this.completedTemplate?.value ? this.completedTemplate.value : null,
        rejectedEmailTemplateID: this.rejectedTemplate?.value ? this.rejectedTemplate.value : null,
        recalledEmailTemplateID: this.recalledTemplate?.value ? this.recalledTemplate.value : null,
        applySignatureTimestamp: this.applySignatureTimestamp,
        primaryFieldName: this.attributeField !== '' && this.attributeField?.value ? this.attributeField.value : '',
        sendCompletedCopyToAllSigners: this.sendCopyToAllSigners,
        orderedSignerUserIDs: orderedSigners?.filter(x => {
          const val = this.activeDocument.assignedUserSignatureList?.findIndex(a => {
            return a.userId === x.id
          })
          return val > -1
        }),
        signatureZoneMappings: this.activeDocument?.assignedUserSignatureList ? this.activeDocument.assignedUserSignatureList : null,
        seperateCopyPerSigner: this.separateCopyForSigners,
        createUsers: this.addedUserList?.filter(user => { return !Number(user?.UserId) })?.map(user => {
          return {
            firstName: user?.FirstName ? user.FirstName : '',
            lastName: user?.LastName ? user.LastName : '',
            emailAddress: user?.Email ? user.Email : '',
            phoneNumber: user?.PhoneNumber ? user.PhoneNumber : ''
          }
        }),
        geoLocation: {
          UserType: 'Sender',
          geolocation: this.geolocation,
          ipAddress: this.ipAddress,
          actionTaken: 'Sent',
        },
        linkExpirationType: this.selectedDocLinkExpiration?.value,
        twoFactorAuth: this.twoFactorAuth,
        initiatorEmailOption:  this.initiatorEmailOption?.name ? this.initiatorEmailOption.value : 1,
        aliasSenderName: this.senderName,
        aliasSenderEmail: this.senderEmail,
      }
      payload = this.checkParallelandViewersAndReviewers(payload)
      return payload
    },
    buildStartSignaturePayloadBulkSend(verifyOnly) {
      let fileDataString = this.uploadedCSVFileString
      const indexOf = this.uploadedCSVFileString.indexOf(';base64,')
      if (indexOf >= 0) {
        fileDataString = fileDataString.substring(indexOf + 8)
      }
      const payload = {
        sigDocTemplateID: this.documentID,
        bulkSendDataFile64String: fileDataString,
        onlyVerifyDataFile: verifyOnly,
      }
      return payload
    },
    buildStartSignaturePayloadFromTemplate() {
      const userIdIsDuplicate = []
      const userEmailAsIdDuplicate = []
      const orderedSigners = []
      const createUsers = []
      this.templateTableData.Rows.forEach((row, index) => {
        if (row[this.EXTERNAL_USER_BOOL_INDEX] === false && userIdIsDuplicate.includes(row[this.SIGNER_NAME_INDEX].UserId)) {
          return
        } else if(row[this.EXTERNAL_USER_BOOL_INDEX] === true && userIdIsDuplicate.includes(row[this.EXTERNAL_USER_EMAIL_INDEX])) {
          return
        }
        if (!row[this.EXTERNAL_USER_BOOL_INDEX]) {
          userIdIsDuplicate.push(row[this.SIGNER_NAME_INDEX].UserId)
        } else {
          userEmailAsIdDuplicate.push(row[this.EXTERNAL_USER_EMAIL_INDEX])
        }
        let newOrderedSigner = {
          id: row[this.SIGNER_NAME_INDEX].UserId !== undefined ? String(row[this.SIGNER_NAME_INDEX].UserId) : row[this.EXTERNAL_USER_EMAIL_INDEX].trim(),
          order: this.signingType === 'signingOrder' ? index : 0
        }
        if (row[this.EXTERNAL_USER_BOOL_INDEX] && row[this.EXTERNAL_USER_NAME_INDEX] && row[this.EXTERNAL_USER_EMAIL_INDEX]) {
          let firstName = row[this.EXTERNAL_USER_NAME_INDEX].trim()
          let lastName = ''
          if (firstName.includes(' ')) {
            lastName = firstName.substring(firstName.indexOf(' ')).trim()
            firstName = firstName.substring(0, firstName.indexOf(' ')).trim()
          }
          createUsers.push({ firstName: firstName, lastName: lastName !== '' ? lastName : firstName, emailAddress: row[this.EXTERNAL_USER_EMAIL_INDEX].trim(), roleName: row[this.ROLE_INDEX] })
        } else if (row[this.SIGNER_FIELD_BOOL_INDEX]) {
          let firstName = this.fieldValuesFromModal[row[this.SIGNER_NAME_INDEX].value].trim()
          let lastName = ''
          if (firstName.includes(' ')) {
            lastName = firstName.substring(firstName.indexOf(' ')).trim()
            firstName = firstName.substring(0, firstName.indexOf(' ')).trim()
          }
          createUsers.push({ firstName: firstName, lastName: lastName !== '' ? lastName : firstName, emailAddress: this.fieldValuesFromModal[row[this.EMAIL_ADDRESS_INDEX].value].trim(), roleName: row[this.ROLE_INDEX] })
          newOrderedSigner.id = this.fieldValuesFromModal[row[this.EMAIL_ADDRESS_INDEX].value].trim()
        }
        orderedSigners.push(newOrderedSigner)
      })

      const signatureZones = this.activeDocument.assignedZonesSignatureList.map(zone => {
        const role = this.templateTableData.Rows?.find(row => {
          return zone.sigDocTemplateRoleName === row[0]
        })
        zone.userId = role[this.SIGNER_NAME_INDEX].UserId !== undefined ? role[this.SIGNER_NAME_INDEX].UserId : (
          role[this.EXTERNAL_USER_EMAIL_INDEX].length > 0 ? 
          role[this.EXTERNAL_USER_EMAIL_INDEX].trim() : 
          this.fieldValuesFromModal[role[this.EMAIL_ADDRESS_INDEX].value].trim()
        )
        return zone
      })

      const payload = {
        sendToCcRecipients: this.cleanedUpCCEmails(),
        filePath: this.activeDocument.filePath,
        deleteFileOnSuccess: true,
        formID: (this.isObjectSelected ? this.attributeForm?.value : null),
        fieldValues: this.fieldValuesFromModal,
        documentDisplayName: this.documentName,
        approvedFolderID: this.completedField?.name === 'Storage' ? this.approvedDestinationFolderId : null,
        wfid: this.completedField?.name === 'Workflow' ? this.workflowField.iID : null,
        rejectedFolderID: this.rejectedDestinationFolderId,
        messageForSigners: this.messageForSigners,
        includeDocumentLinkToSigner: this.includeLink,
        newDocumentEmailTemplateID: this.newDocumentTemplate?.value ? this.newDocumentTemplate.value : null,
        completedEmailTemplateID: this.completedTemplate?.value ? this.completedTemplate.value : null,
        rejectedEmailTemplateID: this.rejectedTemplate?.value ? this.rejectedTemplate.value : null,
        recalledEmailTemplateID: this.recalledTemplate?.value ? this.recalledTemplate.value : null,
        applySignatureTimestamp: this.applySignatureTimestamp,
        primaryFieldName: this.attributeField !== '' &&  this.attributeField?.value ? this.attributeField.value : '',
        sendCompletedCopyToAllSigners: this.sendCopyToAllSigners,
        orderedSignerUserIDs: orderedSigners,
        signatureZoneMappings: signatureZones,
        seperateCopyPerSigner: this.separateCopyForSigners,
        createUsers: createUsers,
        geoLocation: {
          UserType: 'Sender',
          geolocation: this.geolocation,
          ipAddress: this.ipAddress,
          actionTaken: 'Sent',
        },
        linkExpirationType: this.selectedDocLinkExpiration?.value,
        initiatorEmailOption:  this.initiatorEmailOption?.name ? this.initiatorEmailOption.value : 1,
        aliasSenderName: this.senderName,
        aliasSenderEmail: this.senderEmail,
      }
      return payload
    },
    cancel() {
      this.$router.push({ name: 'TheSignatureTemplates' })
    },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    cellChangedSigningOrder(options) {
      const row = options?.row
      const column = options?.column
      const value = options?.value
      this.changeCell(row, column, value)
    },
    updateRoleFromDropList(options) {
      this.templateTableData.Rows[options.row][options.column] = options.value
    },
    changeCell(row, column, value) {
      if (this.dropDownIndeces.includes(column)) {
        this.activeDocument.assignedUserSignatureList[row].subType = value
      }
      else {
        this.userList[row][this.columns.UserIndex[column]] = value
      }
      this.refreshTable()
    },
    checkboxClicked(row, type) {
      switch(type) {
        case 'signerField':
          this.templateTableData.Rows[row][this.SIGNER_FIELD_BOOL_INDEX] = !this.templateTableData.Rows[row][this.SIGNER_FIELD_BOOL_INDEX]
          this.templateTableData.Rows[row][this.EXTERNAL_USER_BOOL_INDEX] = false
          break
        case 'externalUser':
          this.templateTableData.Rows[row][this.SIGNER_FIELD_BOOL_INDEX] = false
          this.templateTableData.Rows[row][this.EXTERNAL_USER_BOOL_INDEX] = !this.templateTableData.Rows[row][this.EXTERNAL_USER_BOOL_INDEX]
          break
        default:
          console.warn(`Unknown type value for method: "checkboxClicked()"\nReceived: ${type}`)
          break
      }
      // Clear input and dropdown data so they can't send with empty data
      this.templateTableData.Rows[row][this.SIGNER_NAME_INDEX] = ''
      this.templateTableData.Rows[row][this.EMAIL_ADDRESS_INDEX] = ''
      this.templateTableData.Rows[row][this.EXTERNAL_USER_NAME_INDEX] = ''
      this.templateTableData.Rows[row][this.EXTERNAL_USER_EMAIL_INDEX] = ''
    },
    checkParallelandViewersAndReviewers(payload) {
      if (this.signingType === "simultaneousRouting") {
        for (let i = 0; i < payload.orderedSignerUserIDs.length; i++) {
          payload.orderedSignerUserIDs[i].order = 0
        }
      }
      for (let i = 0; i < payload.orderedSignerUserIDs.length; i++) {
        if (payload.orderedSignerUserIDs[i]?.id === "-1") {
          payload.orderedSignerUserIDs[i].id = this.userList[i].Email
        }
        if (payload.signatureZoneMappings[i]?.objectId === -1) {
          payload.createUsers.push({
            fullName: this.userList[i].FullName,
            emailAddress: this.userList[i].Email,
            roleName: payload.signatureZoneMappings[i].subType,
          })
        }
      }
      payload.signatureZoneMappings = payload?.signatureZoneMappings ? payload.signatureZoneMappings.filter(zone => {
        return zone.objectId !== -1 ? zone : null
      }) : null
      return payload
    },
    cleanedUpCCEmails() {
      let cleanedUpCCEmails = []
      for (let i = 0; i < this.CCEmails.length; i++) {
        if (this.CCEmails[i].value){
          cleanedUpCCEmails.push(this.CCEmails[i].value)
        }
      }
      return cleanedUpCCEmails
    },
    clearFieldErrors() {
      // eslint-disable-next-line
      Object.keys(this.fieldErrorValues).map(key => this.fieldErrorValues[key] = false)
    },
    clickedOutsideLeave() { this.routeTo = null },
    deleteCCEmail(i) {
      this.CCEmails.splice(i, 1)
    },
    async doBulkSendFromFailureDetails() {
      this.showBulkSendFailureDetails = false
      const payload = this.buildStartSignaturePayloadBulkSend(false)
      const res = await this.$store.dispatch('digitalSignature/verifyAndSendBulkSigTemplate', payload)
      this.toggleBulkSendSnackbar(res.totalSuccess, res.totalFailure)
      setTimeout(() => {
        this.$router.push({ name: 'TheSignatureInbox' })
        this.$store.dispatch('mainViews/changeMainNav', 'signatureInbox')
      }, 1500)
    },
    async doBulkSendTemplateMode() {
      if (!this.bulkSendingTemplateMode) {
        const saveRes = await this.saveTemplateData()
        if (!saveRes) {
          this.toggleSnackbar(false)
          return
        }
      }

      const payload = this.buildStartSignaturePayloadBulkSend(true)
      let res = await this.$store.dispatch('digitalSignature/verifyAndSendBulkSigTemplate', payload)

      if (res.totalFailure === 0) {
          payload.onlyVerifyDataFile = false
          res = await this.$store.dispatch('digitalSignature/verifyAndSendBulkSigTemplate', payload)
      } else {
          this.showBulkSendFailureDetails = true
          return
      }
      this.toggleBulkSendSnackbar(res.totalSuccess, res.totalFailure)
      setTimeout(() => {
        this.$router.push({ name: 'TheSignatureInbox' })
        this.$store.dispatch('mainViews/changeMainNav', 'signatureInbox')
      }, 1500)
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    folderDialogOK() {
      if (this.selectedFolder && this.selectedFolder.isSelected === true) {
        this.isDirty = true
        if (this.fileBrowseField === 'approved-destination' && this.selectedFolder?.item?.FolderId && this.selectedFolder?.item?.Name) {
          this.approvedDestinationFolderId = this.selectedFolder.item.FolderId
          this.approvedDestinationFolder = this.selectedFolder.item.Name
        } else if (this.fileBrowseField === 'rejected-destination' && this.selectedFolder?.item?.FolderId && this.selectedFolder?.item?.Name) {
          this.rejectedDestinationFolderId = this.selectedFolder.item.FolderId
          this.rejectedDestinationFolder = this.selectedFolder.item.Name
        }
      } else {
        this.approvedDestinationFolder = null
        this.approvedDestinationFolderId = ''

        this.rejectedDestinationFolderId = null
        this.rejectedDestinationFolder = ''
      }

      this.toggleBrowseFolderDialog()
    },
    async getFormInputs(form) {
      await this.$store.dispatch('common/getIndexFieldsForForm', form?.value)
      this.attributeField = ''
      this.isDirty = true
    },
    async getGeoLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          const lat = pos.coords.latitude
          const lng = pos.coords.longitude
          const acc = pos.coords.accuracy
          this.geolocation = `${lat}, ${lng}, ${acc}`
          this.save()
        }, err => {
          console.error(err)
          this.save()
        }, { timeout: 1000 })
      }
    },
    async getIpAndGeolocationThenSave() {
      this.isLoading = true
      this.ipAddress = await publicIp.v4()
      this.getGeoLocation()
      this.isLoading = false
    },
    getSignerFieldFromName(fieldName) {
      if (fieldName)
        return this.signerFields.find(field => { return field.value === fieldName })
      return null
    },
    getSignerNameFieldFromName(fieldName) {
      if (fieldName)
        return this.signerNameFields.find(field => { return field.value === fieldName })
      return null
    },
    handleFolderSelected() { this.folderSelected = true },
    iconClick(options) {
      const { index } = options
      const [id] = options?.item?.Values
      const rowIndex = options?.rowIndex
      switch (index) {
        case 4:
          this.reorderField('up', id, rowIndex)
          break;
        case 5:
          this.reorderField('down', id, rowIndex)
          break;
        default:
          console.warn('Icon Click action not found')
          break;
      }
    },
    deleteIconClick(rowIndex) {
      if (this.userList[rowIndex].UserId === -1) { 
        this.userList.splice(rowIndex, 1);
        this.refreshTable();
      }
    },
    async validEmail(email) {
      return Boolean(await this.$store.dispatch('common/validateEmail', email))
    },
    async isInputValid() {
      this.fieldErrorValues = {
        approvedDestinationFolder: false,
        ccEmails: false,
        completedDestination: false,
        documentName: false,
        documentNameTrimmed: false,
        mappingConfiguration: false,
        messageForSigners: false,
        object: false,
        rejectedDestinationFolder: false,
        selectedDocLinkExpiration: false,
        signingOptions: false,
        workflowField: false,
      }

      for (let i = 0; i < this.CCEmails.length; i++){
        if (!(await this.validEmail(this.CCEmails[i].value))) this.fieldErrorValues.ccEmails = true
      }
      if (!this.documentName.length) this.fieldErrorValues.documentName = true
      if (this.documentName.length > 0 && this.documentName.trim() === '') this.fieldErrorValues.documentNameTrimmed = true
      if (!this.completedField) this.fieldErrorValues.completedDestination = true
      if (this.completedField && this.completedField?.name === 'Workflow' && !this.workflowField) this.fieldErrorValues.workflowField = true
      if (this.completedField && this.completedField?.name === 'Storage' && !this.approvedDestinationFolder.length) this.fieldErrorValues.approvedDestinationFolder = true
      if (!this.rejectedDestinationFolder.length) this.fieldErrorValues.rejectedDestinationFolder = true
      if (!this.messageForSigners.length && !this.newDocumentTemplate?.value) this.fieldErrorValues.messageForSigners = true
      if (!this.selectedDocLinkExpiration || !this.selectedDocLinkExpiration?.value) this.fieldErrorValues.selectedDocLinkExpiration = TheReportVue
      if (this.bulkSendingEnabled && !this.isObjectSelected) this.fieldErrorValues.object = true
      if (!await this.validEmail(this.senderEmail)) this.fieldErrorValues.signingOptions = true
      if (!this.isMappingConfigurationValid()) this.fieldErrorValues.mappingConfiguration = true

      const snackBarHtml = `
      <ul class="vasion-ul" style="width: 500px; position: relative; right: 7px">
        <li style="display: ${this.fieldErrorValues.documentName ? 'inherit' : 'none'}">- Document Name</li>
        <li style="display: ${this.fieldErrorValues.documentNameTrimmed ? 'inherit' : 'none'}">- Document Name cannot only include spaces</li>
        <li style="display: ${this.fieldErrorValues.completedDestination ? 'inherit' : 'none'}">- Completed destination</li>
        <li style="display: ${this.fieldErrorValues.workflowField ? 'inherit' : 'none'}">- Workflow Field</li>
        <li style="display: ${this.fieldErrorValues.approvedDestinationFolder ? 'inherit' : 'none'}">- Approved destination folder</li>
        <li style="display: ${this.fieldErrorValues.rejectedDestinationFolder ? 'inherit' : 'none'}">- Rejected destination folder</li>
        <li style="display: ${this.fieldErrorValues.messageForSigners ? 'inherit' : 'none'}">- Either the Message for Signers, or New Document Email Template</li>
        <li style="display: ${this.fieldErrorValues.selectedDocLinkExpiration ? 'inherit' : 'none'}">- Document Link Expiration</li>
        <li style="display: ${this.fieldErrorValues.object ? 'inherit' : 'none'}">- ${this.$formsLabel}</li>
        <li style="display: ${this.fieldErrorValues.ccEmails ? 'inherit' : 'none'}">- CC Recipients</li>
        <li style="display: ${this.fieldErrorValues.signingOptions ? 'inherit' : 'none'}">- Please provide a valid email under SENDER EMAIL</li>
        <li style="display: ${this.fieldErrorValues.mappingConfiguration ? 'inherit' : 'none'}">- Please provide a valid Mapping Configuration:<br><ul class="vasion-ul">Each chosen Signer Field must have a <b>'Map To'</b> Value</ul></li>
      </ul>
      `
      this.snackbarSubTitle = snackBarHtml
      const isInvalid = Object.values(this.fieldErrorValues).includes(true)
      if (isInvalid) {
        this.snackbarTitle = 'Missing / Incorrect Data '
        this.showRawHtml = true
        this.showSnackbar = true
      }
      return !isInvalid
    },
    isMappingConfigurationValid() {
      if (!this.bulkSendingEnabled) return true

      let fieldsTocheck = []
      let allSignatureFieldNamesPresents = true
      this.templateTableData.Rows.map((role) => {
        if (role[3]) {
          if (role[2]?.value) {
            fieldsTocheck.push(role[2]?.value)
          } else {
            allSignatureFieldNamesPresents = false
          }
        }
      })

      if (!allSignatureFieldNamesPresents) return false

      const checkAllFields = fieldsTocheck.every((field) => field in this.mappingConfiguration && this.mappingConfiguration[field]);

      return checkAllFields
    },
    async loadSavedTemplate() {
      this.documentID = this.$route.params.documentId
      if (this.documentID === 0) {
        this.setTempSignatureData()
        await this.setTemplateTableData(null)
        return
      }
      if (this.activeDocument.formID !== null) {
        await this.$store.dispatch('attributeForm/getFormDetails', this.activeDocument.formID)
        const activeForm = this.$store.state.attributeForm.activeIndexForm
        const form = { name: activeForm?.name, value: activeForm.ID }
        this.attributeForm = form
        await this.getFormInputs(form)
      }
      if (this.documentID > 0) {
        this.includeLink = this.activeDocument.includeDocumentLinkToSigner
      } else {
        this.includeLink = true
      }
      this.CCEmails = []
      for (let i = 0; i < this.activeDocument.sendToCcRecipients.length; i++) {
        this.CCEmails.push( {value: this.activeDocument.sendToCcRecipients[i]} )
      }
      this.documentName = this.activeDocument.templateName
      this.approvedDestinationFolderId = this.activeDocument.approvedFolderID
      this.approvedDestinationFolder = this.activeDocument.approvedFolderName
      this.workflowField = this.workflowList?.find(wf => wf.iID === this.activeDocument.wfid)
      this.completedField = this.approvedDestinationFolderId !== null ? { name: 'Storage' } : { name: 'Workflow' }
      this.rejectedDestinationFolderId = this.activeDocument.rejectedFolderID
      this.rejectedDestinationFolder = this.activeDocument.rejectedFolderName
      this.messageForSigners = this.activeDocument.messageForSigners
      this.newDocumentTemplate = this.activeDocument.newDocumentEmailTemplateID ? { value: this.activeDocument.newDocumentEmailTemplateID } : { value: '' }
      this.completedTemplate = this.activeDocument.completedEmailTemplateID ? { value: this.activeDocument.completedEmailTemplateID } : { value: '' }
      this.rejectedTemplate = this.activeDocument.rejectedEmailTemplateID ? { value: this.activeDocument.rejectedEmailTemplateID } : { value: '' }
      this.recalledTemplate = this.activeDocument.recalledEmailTemplateID ? { value: this.activeDocument.recalledEmailTemplateID } : { value: '' }
      this.applySignatureTimestamp = this.activeDocument.applySignatureTimestamp
      this.sendCopyToAllSigners = this.activeDocument.sendCompletedCopyToAllSigners
      let primaryField = this.activeDocument.primaryFieldName
      if (primaryField && primaryField !== '') {
         primaryField = this.indexFieldList.find(field => field.value === primaryField)
      }
      this.attributeField = primaryField
      this.assignedUsers = this.activeDocument.accessUserIDs.map(x => this.availableUsers?.find(y => y?.value === x))
      this.assignedGroups = this.activeDocument.accessGroupIDs.map(x => this.availableGroups?.find(y => y?.value === x))
      this.availableUsers = this.availableUsers?.filter(x => !this.assignedUsers.includes(x))
      this.availableGroups = this.availableGroups?.filter(x => !this.assignedGroups.includes(x))
      this.senderName = this.activeDocument.bulkSenderName
      this.senderEmail = this.activeDocument.bulkSenderEmail
      this.bulkSendingEnabled = this.activeDocument.isBulkSending
      this.mappingConfiguration = this.activeDocument.bulkSendMapping
      this.fileDelimiter = this.activeDocument.bulkSendDelimiter
      this.selectedDocTemplate = this.activeDocument.bulkSendCreateDocTemplateID ? this.docTemplates?.find(val => val.id === this.activeDocument.bulkSendCreateDocTemplateID.toString()) : {}
      this.initiatorEmailOption = this.initiatorEmailOptionList?.find(init => init?.value === this.activeDocument.initiatorEmailOption)

      const foundExp = this.linkExpirationOptions?.find(exp => { if (exp?.value === this.activeDocument.linkExpirationType) return exp })
      if (foundExp) {
        this.selectedDocLinkExpiration = foundExp
      }

      const rolesWithAssignedZone = this.activeDocument.roles.filter(docRole => {
        return this.activeDocument.assignedZonesSignatureList.some(zonedRole => (zonedRole.sigDocTemplateRoleName === docRole.sigDocTemplateRoleName || docRole.sigDocTemplateRoleName === "Reviewer" || docRole.sigDocTemplateRoleName === "Viewer"))
      })
      await this.setTemplateTableData(rolesWithAssignedZone)
    },
    loadUsersAndGroups() {
      this.availableUsers = this.users?.map((user) => {
        return {
          name: user.name,
          value: user.value,
        }
      })
      this.availableGroups = this.groups?.map((group) => {
        return {
          name: group.name,
          value: group.value,
        }
      })
    },
    markClean() { this.isDirty = false },
    markDirty() { this.isDirty = true },
    markModalClean() { this.isModalFormDirty = false },
    markModalDirty() { this.isModalFormDirty = true },
    markIsValid() { this.isModalFormValid = true },
    markNotValid() { this.isModalFormValid = true },
    async openConfigureMapping() {
      await this.$store.dispatch('attributeForm/getFormDetails', this.attributeForm?.value)
      this.configTableRows = this.$store.state?.attributeForm?.activeIndexForm?.fields?.map(field => {
        return {
          name: field.name,
          title: field.title,
          columns: this.mappingConfigurationColumnsArray,
        }
      })
      this.showConfigureMapping = true
    },
    moveTemplateRows(row1Index, row2Index) {
      const row1 = this.templateTableData.Rows[row1Index]
      this.templateTableData.Rows[row1Index] = this.templateTableData.Rows[row2Index]
      this.templateTableData.Rows[row2Index] = row1
    },
    onEnableBulkSendingChange() {
      if (!this.isObjectSelected && this.bulkSendingEnabled === false) {
        this.showBulkSendingConfigWarning = true
        return
      }
      this.toggleCheckbox('bulkSendingEnabled')
    },
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      if (file?.name.endsWith('.csv')) {
        this.uploadedCSVFileString = await toBase64(file)
        this.uploadedCSVFileName = file?.name

        if (!this.pairedDownView) {
          this.mappingConfiguration = {}

          const base64String = this.uploadedCSVFileString.split('base64,')[1]
          if (!base64String){
            this.snackbarTitle = 'Empty CSV can not be prepared for bulk sending'
            this.showRawHtml = true
            this.showSnackbar = true
            return
          }
          const csvString = atob(base64String)
          const csvRows = csvString.split('\r\n')
          const tempColumns = csvRows[0].split(this.fileDelimiter).map(substring => substring.replace(/['"]/g, ''));
          this.mappingConfigurationColumnsArray = tempColumns.map(col => {
            return { name: col }
          })
        }

        progress(true, 0, 1024)
        load(file?.name)
      } else {
        error(true)
        this.showRawHtml = true
        this.snackbarImage = false
        this.snackbarTitle = "ERROR"
        this.snackbarSubTitle = "Can only upload files that end in \".csv\"."
        this.showSnackbar = true
      }

      return {
        abort: () => {
          abort();
        },
      };
    },
    async refreshSignersTable() {
      const data = {
        userList: this.userList,
        columns: this.signersColumns,
        zoneSignatureMappings: this.activeDocument?.assignedUserSignatureList ? this.activeDocument.assignedUserSignatureList : null,
      }
      this.signersTableData = await this.$store.dispatch('digitalSignature/setTableData', data)
    },
    async refreshTable() {
      let zoneMappings = []
      if (this.activeDocument?.assignedUserSignatureList){
        for (let i = 0; i < this.activeDocument?.assignedUserSignatureList.length; i++) {
          let zone = {}
          for (const property in this.activeDocument?.assignedUserSignatureList[i]) {
            if (property === "subType")
              if (this.activeDocument?.assignedUserSignatureList[i][property] === "initial")
                zone[property] = "Initials"
              else if (this.activeDocument?.assignedUserSignatureList[i][property] === "signature")
                zone[property] = "Signature"
              else if (this.activeDocument?.assignedUserSignatureList[i][property] === "timestamp")
                zone[property] = "Date"
              else if (this.activeDocument?.assignedUserSignatureList[i][property] === "text")
                zone[property] = "Text"
              else
                zone[property] = this.activeDocument?.assignedUserSignatureList[i][property]
            else
              zone[property] = this.activeDocument?.assignedUserSignatureList[i][property]
          }
          zoneMappings.push(zone)
        }
      }
      const data = {
        userList: this.userList,
        columns: this.columns,
        separateCopyForSigners: this.separateCopyForSigners,
        zoneSignatureMappings: this.activeDocument?.assignedUserSignatureList ? zoneMappings : null,
      }
      this.tableData = await this.$store.dispatch('digitalSignature/setTableData', data)
    },
    reorderField(direction, userId, rowIndex) {
      const newIndex = direction === 'up' ? (rowIndex - 1) : (rowIndex + 1);

      let fieldBeingMoved = this.userList[rowIndex]
      this.userList.splice(rowIndex, 1)
      this.userList.splice(newIndex, 0, fieldBeingMoved)

      let zone = this.activeDocument?.assignedUserSignatureList[rowIndex]
      this.activeDocument?.assignedUserSignatureList.splice(rowIndex, 1)
      this.activeDocument?.assignedUserSignatureList.splice(newIndex, 0, zone)

      this.refreshTable()

      let userIndex1 = this.blankUsers.findIndex((e) => e === rowIndex)
      let userIndex2 = this.blankUsers.findIndex((e) => e === newIndex)

      if (this.blankUsers[userIndex1] === rowIndex) {
        this.blankUsers[userIndex1] = newIndex
      } else if (this.blankUsers[userIndex1] === newIndex) {
        this.blankUsers[userIndex1] = rowIndex
      }
      if (this.blankUsers[userIndex2] === rowIndex) {
        this.blankUsers[userIndex2] = newIndex
      } else if (this.blankUsers[userIndex2] === newIndex) {
        this.blankUsers[userIndex2] = rowIndex
      }
    },
    async save() {
      if (!await this.isInputValid()) {
        return
      }

      if ((this.bulkSendingTemplateMode || this.bulkSendingEnabled) && this.uploadedCSVFileString === '') {
        this.toggleBulkSendUploadDialog()
        return
      }

      this.isDirty = false
      this.isModalFormDirty = false
      await this.$store.dispatch('digitalSignature/setSignatureTempData', {})
      await this.$store.dispatch('digitalSignature/setKeepDataOnReturn', false)

      if (this.isObjectSelected && this.showSendAttributeFormModal === false && !this.bulkSendingEnabled) {
        this.showSendAttributeFormModal = true
        return
      }

      this.showSendAttributeFormModal = false
      let payload = {}

      if (this.bulkSendingTemplateMode || this.bulkSendingEnabled) {
        this.doBulkSendTemplateMode()
        return
      } else if (this.activeDocument.isTemplate) {
        payload = this.buildStartSignaturePayloadFromTemplate()
      } else {
        payload = this.buildStartSignaturePayload()
      }

      const res = await this.$store.dispatch('digitalSignature/startSignatureDocument', payload)
      this.toggleSnackbar(res)
      if (res) {
        await this.$amplitude.trackEvent('Signature Doc Started', {
          'signatureInitiationType': this.activeDocument.isTemplate ? 'Template' : 'Single',
        })
      
        setTimeout(() => {
          this.$store.dispatch('digitalSignature/setAddedUsersList', [])
          this.$router.push({ name: 'TheSignatureInbox' })
          this.$store.dispatch('mainViews/changeMainNav', 'signatureInbox')
        }, 1500)
      }
    },
    async saveAsTemplate() {
      if (await this.isInputValid()) {
        const res = await this.saveTemplateData()
        this.toggleSnackbar(res)
        if (res) {
          setTimeout(() => {
            this.$router.push({ name: 'TheSignatureTemplates' })
          }, 1500)
        }
      }
    },
    async saveFromModal(params)
    {
      const returnObj = {}
      params.fieldValues?.map(x => {
        returnObj[`${x?.Key}`] = Array.isArray(x?.Value) ? x?.Value[0] : x?.Value
        return x
      })
      this.fieldValuesFromModal = returnObj
      this.getIpAndGeolocationThenSave()
    },
    async saveTemplateData() {
        this.isDirty = false
        this.isModalFormDirty = false
        await this.$store.dispatch('digitalSignature/setSignatureTempData', {})
        const payload = this.buildSaveTemplatePayload()
        this.showSendAttributeFormModal = false
        const res = await this.$store.dispatch('digitalSignature/saveSignatureDocumentTemplate', payload)
        return res
    },
    setMappingConfiguration() {
      Object.entries(this.mappingConfiguration).forEach(val => {
        this.mappingConfiguration[val[0]] = val[1]?.name
      })
      this.showConfigureMapping = false
    },
    //This gets called at the beginning of template creation and any time table is updated to update the template data and check if table is filled out.
    setTemplateReadyToSend() {
      if (!this.$refs.TemplateMappingTable)
        return
      let obj = this.$refs.TemplateMappingTable.formatForSave()
      for (let i = 0; i < obj.users.length; i++) {
        //1 is where the user information lives for each entrant
        this.templateTableData.Rows[obj.indeces[i]][1] = obj.users[i]
      }

      let newReadyToSend = true

      if (!this.templateTableData || !this.templateTableData.Rows || this.templateTableData.Rows.length == 0) {
        newReadyToSend = false
      }

      if (this.pairedDownView) {
        this.templateTableData.Rows.forEach(row => {
          if (!row[this.EXTERNAL_USER_BOOL_INDEX] && !row[this.SIGNER_FIELD_BOOL_INDEX] && (!row[this.SIGNER_NAME_INDEX].FullName || row[this.SIGNER_NAME_INDEX].FullName === '')) {
            newReadyToSend = false
          } else if (!row[this.EXTERNAL_USER_BOOL_INDEX] && row[this.SIGNER_FIELD_BOOL_INDEX] && (!row[this.SIGNER_NAME_INDEX].name || row[this.SIGNER_NAME_INDEX].name === '')) {
            newReadyToSend = false
          } else if (row[this.EXTERNAL_USER_BOOL_INDEX] && (!row[this.EXTERNAL_USER_NAME_INDEX] || row[this.EXTERNAL_USER_NAME_INDEX].trim() === '' || !row[this.EXTERNAL_USER_EMAIL_INDEX] || row[this.EXTERNAL_USER_EMAIL_INDEX].trim() === '')) {
            newReadyToSend = false
          }
        })
      } else {
        newReadyToSend = !this.templateTableData.Rows.some(row => {
          return row[this.SIGNER_FIELD_BOOL_INDEX] // not ready if signer field is checked
            || (row[this.EXTERNAL_USER_BOOL_INDEX] && (!row[this.EXTERNAL_USER_NAME_INDEX] || row[this.EXTERNAL_USER_NAME_INDEX].trim() === '' || !row[this.EXTERNAL_USER_EMAIL_INDEX] || row[this.EXTERNAL_USER_EMAIL_INDEX].trim() === '')) //not ready if external user is checked and missing name or email
            || (!row[this.SIGNER_FIELD_BOOL_INDEX] && !row[this.EXTERNAL_USER_BOOL_INDEX] && (!row[this.SIGNER_NAME_INDEX].FullName || row[this.SIGNER_NAME_INDEX].FullName === '')) //not ready if using Internal User but user not selected
            || (row[this.SIGNER_FIELD_BOOL_INDEX] && !row[this.EXTERNAL_USER_BOOL_INDEX] && (!row[this.EMAIL_ADDRESS_INDEX].value || row[this.EMAIL_ADDRESS_INDEX].value === '')) //not ready if using Signer Field but field not selected
        })
      }

      this.templateTableMappingReadyToSend = newReadyToSend
    },
    async setTemplateTableData(roles) {
      this.templateTableData.Rows = []
      let Rows = []
      let foundRoles = []
      this.originalRoles = this.activeDocument?.roles
      if (roles){
        //We are building from a saved template and we need both empty users and saved known zones
        for (let i = 0; i < roles.length; i++) {
          const role = this.originalRoles?.find(r => { return r.sigDocTemplateRoleName === roles[i].sigDocTemplateRoleName })
          if (roles[i].roleOrder > 0) {
            this.signingType = 'signingOrder'
          }          
          if (role) {
            if (role.userID) {
              Rows.push([
                role.sigDocTemplateRoleName,
                role.userID,
                "",
                false,
                false,
                "",
                "",
                role.sigDocTemplateRoleName === "Reviewer" || role.sigDocTemplateRoleName === "Viewer" ? true : null,
                ])
            } else if (role.signatureFieldName !== "") {
              Rows.push([
                role.sigDocTemplateRoleName,
                this.getSignerNameFieldFromName(role.signerNameFieldName),
                this.getSignerFieldFromName(role.signatureFieldName),
                true,
                false,
                "",
                "",
                role.sigDocTemplateRoleName === "Reviewer" || role.sigDocTemplateRoleName === "Viewer" ? true : null,
                ])
            } else {
              Rows.push([
                role.sigDocTemplateRoleName,
                "",
                "",
                false,
                true,
                role.externalUserFirstAndLastName,
                role.externalUserEmail,
                role.sigDocTemplateRoleName === "Reviewer" || role.sigDocTemplateRoleName === "Viewer" ? true : null,
              ])
            }
          } else {
            Rows.push([role.sigDocTemplateRoleName, '', '', false, false, '', ''])
          }
          foundRoles.push(role.sigDocTemplateRoleName)
        }
        //Check for any new roles being added.
        this.activeDocument.assignedZonesSignatureList?.forEach(zone => {
          if (foundRoles.includes(zone.sigDocTemplateRoleName)) {
            // the Role is already represented in the table data - can't have duplicate Roles
            return
          }

          foundRoles.push(zone.sigDocTemplateRoleName)
          const role = this.originalRoles?.find(r => { return r.sigDocTemplateRoleName === zone.sigDocTemplateRoleName })
          if (role) {
            Rows.push([
              zone.sigDocTemplateRoleName,
              this.getSignerNameFieldFromName(role.signerNameFieldName) ?? role.userID ?? '',
              this.getSignerFieldFromName(role.signatureFieldName) ?? role.userID,
              role.signatureFieldName !== '',
              role.roleOrder,
            ])
          } else {
            Rows.push([zone.sigDocTemplateRoleName, '', '', false, false, '', ''])
          }
        })
      } else {
        this.activeDocument.assignedZonesSignatureList?.forEach(zone => {
          if (foundRoles.includes(zone.sigDocTemplateRoleName)) {
            // the Role is already represented in the table data - can't have duplicate Roles
            return
          }

          foundRoles.push(zone.sigDocTemplateRoleName)
          const role = this.originalRoles?.find(r => { return r.sigDocTemplateRoleName === zone.sigDocTemplateRoleName })
          if (role) {
            if (role.roleOrder > 0) {
              this.signingType = 'signingOrder'
            }
            Rows.push([
              zone.sigDocTemplateRoleName,
              this.getSignerNameFieldFromName(role.signerNameFieldName) ?? role.userID ?? '',
              this.getSignerFieldFromName(role.signatureFieldName) ?? role.userID,
              role.signatureFieldName !== '',
              role.roleOrder,
            ])
          } else {
            Rows.push([zone.sigDocTemplateRoleName, '', '', false, false, '', ''])
          }
        })

        if (Rows && this.signingType === 'signingOrder') {
          Rows.sort((a, b) => {
            const x = a[4]
            const y = b[4]
            if (x < y) { return -1 }
            if (x > y) { return 1 }
            return 0
          })
        }
      }
      this.templateTableData.Rows = Rows
      this.setTemplateReadyToSend()
    },
    setTempSignatureData() {
      const tempData = this.$store.state.digitalSignature.signatureTempData

      this.completedField = tempData?.completedField ? tempData.completedField : ''
      this.documentName = tempData?.documentName ? tempData.documentName : ''
      this.attributeForm = tempData?.attributeForm ? tempData.attributeForm : ''
      this.attributeField = tempData?.attributeField ? tempData.attributeField : ''
      this.approvedDestinationFolder = (tempData?.approvedDestinationFolder && tempData?.completedField) ? tempData.approvedDestinationFolder : ''
      this.approvedDestinationFolderId = (tempData?.approvedDestinationFolderId && tempData?.completedField) ? tempData.approvedDestinationFolderId : 0
      this.rejectedDestinationFolder = (tempData?.rejectedDestinationFolder && tempData?.completedField) ? tempData.rejectedDestinationFolder : ''
      this.rejectedDestinationFolderId = (tempData?.rejectedDestinationFolderId && tempData?.completedField) ? tempData.rejectedDestinationFolderId : 0
      this.messageForSigners = tempData?.messageForSigners ? tempData.messageForSigners : ''
      this.includeLink = tempData.includeLink !== undefined && tempData.includeLink !== null ? tempData.includeLink : true
      this.signingType = tempData?.signingType ? tempData.signingType : 'simultaneousRouting'
      this.applySignatureTimestamp = tempData?.applySignatureTimestamp ? tempData.applySignatureTimestamp : false
      this.newDocumentTemplate = tempData.newDocumentTemplate?.value ? tempData.newDocumentTemplate : ''
      this.completedTemplate = tempData.completedTemplate?.value ? tempData.completedTemplate : ''
      this.sendCopyToAllSigners = tempData?.sendCopyToAllSigners !== undefined && tempData?.sendCopyToAllSigners !== null ? tempData.sendCopyToAllSigners : true
      this.rejectedTemplate = tempData.rejectedTemplate?.value ? tempData.rejectedTemplate : ''
      this.recalledTemplate = tempData.recalledTemplate?.value ? tempData.recalledTemplate : ''
      this.assignedUsers = tempData.assignedUsers ?? []
      this.availableUsers = this.assignedUsers.length === 0 ? this.availableUsers : tempData.availableUsers
      this.assignedGroups = tempData.assignedGroups ?? []
      this.availableGroups = this.assignedGroups.length === 0 ? this.groups : tempData.availableGroups
    },
    toggleCheckbox(id) {
      this[id] = !this[id]
      this.isDirty = true

      if (id === 'bulkSendingEnabled') {
        this.fileDelimiter = this.bulkSendingEnabled ? ',' : ''
        this.selectedDocTemplate = {}
      }
    },
    toggleBrowseFolderDialog(field) {
      this.folderSelected = false
      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog

      this.fileBrowseField = ''
      if (this.showBrowseFoldersDialog && field && field !== '') {
        this.fileBrowseField = field
      }
    },
    toggleBulkSendSnackbar(success, failure) {
      this.snackbarImage = (failure === 0)
      this.snackbarLinkText = ''
      if (failure === 0) {
        this.snackbarTitle = ''
        this.snackbarSubTitle = (parseInt(success) === 0) ? "Bulk send successfully sent." : `Success! ${success} records sent.`
        this.showRawHtml = false
        this.showSnackbar = true
        if (success !== 0)
        {
          this.showRawHtml = true
        }
      } else {
        const total = success + failure
        this.snackbarTitle = ''
        this.snackbarLinkText = 'View Failed Records'
        this.snackbarSubTitle = `${failure} of ${total} failed to send. ${success} records sent.`
        this.showRawHtml = true
        this.showSnackbar = true
      }
    },
    toggleBulkSendUploadDialog() {
      this.isBulkSendUpload = true
      this.showUploadDialog = !this.showUploadDialog
    },
    toggleCollapsableSection(section) {
      switch (section) {
        case 'signing-options':
          this.showSigningOptions = !this.showSigningOptions
          break;
        case 'email-options':
          this.showEmailOptions = !this.showEmailOptions
          break;
        case 'configure-signers':
          this.showConfigureSigners = !this.showConfigureSigners
          break;
        default:
          this.showSigningOptions = false
          break;
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    toggleSnackbar(result) {
      this.snackbarImage = result
      this.showRawHtml = false
      if (result) {
        this.snackbarTitle = 'SUCCESS'
        this.snackbarSubTitle = 'Save was a success!'
      } else {
        this.snackbarTitle = 'FAILURE'
        this.snackbarSubTitle = 'Something went wrong please see your administrator'
      }
      this.showSnackbar = true
    },
    toggleUploadDialog() {
      this.showUploadDialog = !this.showUploadDialog
    },
    uncheckWhenDisabled(row, column) {
      this.templateTableData.Rows[row][column] = false
    },
    deleteParticipantTemplate(row) {
      this.templateTableData.Rows.splice(row, 1);
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

.separator-div {
  height: 40px;
}

.error-state-container {
  border: 1px solid $error-red !important;
}
.error-state-label {
  color: $error-red !important;
}
.error-container-state {
  border: 1px solid $error-red !important;
  border-radius: 8px;
  width: 98% !important;
}

#file-delimiter {
  margin-left: 16px;
}

#configure-mapping-button {
  margin-left: 385px;
}

#mapping-config-table td, #mapping-config-table th {
  padding: 0 0 0 8px;
}

#edit-new-document {
  #main-section {
    #edit-new-document-details {
      width: 100%;
      .row {
          width: 100%;
          margin: 13px 0;
          display: flex;
          align-items: center;

          .subheader {
            @include SubHeadline;
          }

          .row-field {
            margin: 0 16px 5px 0;
          }

          .browse-input {
            margin-right: 5px;
          }

          .browse-button {
            margin-top: 15px;
            margin-right: 12px;
            display: flex;
            align-items: center;
          }
        }
      .collapsable-section {
          float: left;
          margin-top: 20px;
          width: 100%;
          .subheader {
            @include SubHeadline;
            width: 225px;
            display: block;
            float: left;
            margin-top: 5px;
          }
        }
    }
    #message-content {
      width: 810px;
    }
  }
  .paddingRight {
    padding-right: 15px;
  }
}

#configure-mapping-container {
  display: flex;
  width: 1000px;
  max-width: 1000px;
}

#mapping-config-table {
  width: 100%;

  tr:first-child {
    border-bottom: solid 1px #CFD2D4;
  }
  tr:nth-child(even) {
    background: $grey-50;
  }
  tr {
    border-bottom: solid 1px #EBECEC;
    height: 56px;
  }

  .first {
    width: 40%;
  }
  .second {
    width: 60%;
  }
}

#failed-records-div
{
  width: 500px;
}

::v-deep .table-data {
  padding: 10px 0 10px 15px;
}

::v-deep .vasion-html-table-default table td > div {
  padding: 0px;
}

.w100 {
  width: 100%;
}

::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
  color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
  border-color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
  background-color: $secondary !important;
}
</style>
