<template>
  <table>
    <tr v-if="tableData && tableData.Columns && tableData.Columns.Values" class="header-row">
      <th v-for="(headerColumn, column) in tableData.Columns.Values" :key="column" class="table-header">
        <label> {{ headerColumn }} </label>
      </th>
    </tr>
    <tr v-for="(rowValues, row) in tableData.Rows" :key="row" class="table-row">
      <td v-if="!rowValues[USER_IS_BLANK]" class="table-data" :class="{'grey-background': row % 2 === 0}">
        {{ rowValues[ROLE_INDEX] }}
      </td>
      <td v-else class="table-data" :class="{'grey-background': row % 2 === 0}">
        <VasionDropList
          v-slot="slotItem"
          v-model="rowValues[ROLE_INDEX]"
          inputType="blank-white"
          class="action-input td-set-width"
          :dataArray="roleArray"
          :isDisabled="isDisabled"
          type="plain-list"
          valueName="value"
          displayName="value"
          @input="updateRoleFromDropList({row: row, column: ROLE_INDEX, value: rowValues[ROLE_INDEX].value})"
        >
          {{ slotItem.item.value }}
        </VasionDropList>
      </td>
      <td class="table-data" :class="{'grey-background': row % 2 === 0}">
        <VasionInput
          v-if="!rowValues[SIGNER_FIELD_BOOL_INDEX] && rowValues[EXTERNAL_USER_BOOL_INDEX]"
          :id="`name-input-${row}`"
          v-model="rowValues[EXTERNAL_USER_NAME_INDEX]"
          inputType="blank-white"
          class="action-input td-set-width"
          :isDisabled="isDisabled"
          @input="updateReadyToSend()"
        />
        <VasionDropList
          v-else-if="!rowValues[SIGNER_FIELD_BOOL_INDEX] && !rowValues[EXTERNAL_USER_BOOL_INDEX]"
          :id="`name-droplist-${row}`"
          v-slot="slotItem"
          v-model="rowValues[SIGNER_NAME_INDEX]"
          class="td-set-width"
          :dataArray="userList"
          type="plain-list"
          valueName="UserId"
          displayName="FullName"
          :isDisabled="rowValues[SIGNER_FIELD_BOOL_INDEX] || isDisabled"
          @input="updateReadyToSend()"
        >
          {{ slotItem.item.FullName }}
        </VasionDropList>
        <VasionDropList
          v-else
          :id="`signer-name-field-droplist-${row}`"
          v-slot="slotItem"
          v-model="rowValues[SIGNER_NAME_INDEX]"
          class="td-set-width"
          :dataArray="signerNameFields"
          type="plain-list"
          valueName="fieldID"
          displayName="name"
          :isDisabled="isDisabled"
          @input="updateReadyToSend()"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </td>
      <td class="table-data" :class="{'grey-background': row % 2 === 0}">
        <VasionInput
          v-if="!rowValues[SIGNER_FIELD_BOOL_INDEX] && rowValues[EXTERNAL_USER_BOOL_INDEX]"
          :id="`email-input-${row}`"
          v-model="rowValues[EXTERNAL_USER_EMAIL_INDEX]"
          inputType="blank-white"
          class="action-input td-set-width"
          :isDisabled="isDisabled"
          @input="updateReadyToSend()"
        />
        <VasionDropList
          v-else-if="!rowValues[SIGNER_FIELD_BOOL_INDEX] && !rowValues[EXTERNAL_USER_BOOL_INDEX]"
          :id="`email-droplist-${row}`"
          v-slot="slotItem"
          v-model="rowValues[SIGNER_NAME_INDEX]"
          class="td-set-width"
          :dataArray="userList"
          type="plain-list"
          valueName="UserId"
          displayName="Email"
          :isDisabled="isDisabled"
          @input="updateReadyToSend()"
        >
          {{ slotItem.item.Email }}
        </VasionDropList>
        <VasionDropList
          v-else
          :id="`signer-field-droplist-${row}`"
          v-slot="slotItem"
          v-model="rowValues[EMAIL_ADDRESS_INDEX]"
          class="td-set-width"
          :dataArray="signerFields"
          type="plain-list"
          valueName="fieldID"
          displayName="name"
          :isDisabled="isDisabled"
          @input="updateReadyToSend()"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </td>
      <td class="table-data" :class="{'grey-background': row % 2 === 0}">
        <VasionCheckbox
          :id="`signer-field-checkbox-${row}`"
          :checked="rowValues[SIGNER_FIELD_BOOL_INDEX]"
          :isDisabled="(signerFields.length === 0 || pairedDownView || isDisabled)"
          @disabled="uncheckWhenDisabled(row, SIGNER_FIELD_BOOL_INDEX)"
          @change="checkboxClicked(row, 'signerField')"
          @value="$forceUpdate()"
        />
      </td>
      <td class="table-data" :class="{'grey-background': row % 2 === 0}">
        <VasionCheckbox
          :id="`external-user-checkbox-${row}`"
          :checked="rowValues[EXTERNAL_USER_BOOL_INDEX]"
          :isDisabled="isDisabled"
          @disabled="uncheckWhenDisabled(row, EXTERNAL_USER_BOOL_INDEX)"
          @change="checkboxClicked(row, 'externalUser')"
          @value="$forceUpdate()"
        />
      </td>
      <td id="reordering-arrows" class="table-data" :class="{'grey-background': row % 2 === 0}">
        <VasionDeleteIcon
          v-if="rowValues[USER_IS_BLANK] && !isDisabled"
          class="reorder-arrow"
          title="Delete registry"
          @click.stop="deleteRow(row)"
        />
        <VasionArrowUpIcon
          v-if="allowFieldReordering && !pairedDownView && !isDisabled"
          class="reorder-arrow"
          title="Move Up"
          :disabled="row === 0"
          :class="{ 'vasion-icon-disabled': row === 0 }"
          @click.stop="moveRow(row, row - 1)"
        />
        <VasionArrowDownIcon
          v-if="allowFieldReordering && !pairedDownView && !isDisabled"
          class="reorder-arrow"
          title="Move Down"
          :disabled="row >= tableData.Rows.length - 1"
          :class="{ 'vasion-icon-disabled': row >= tableData.Rows.length - 1 }"
          @click.stop="moveRow(row, row + 1)"
        />
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'TemplateMappingTable',
  props: {
    allowFieldReordering: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    roleArray: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    signerFields: {
      type: Array,
      required: true,
    },
    signerNameFields: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Object,
      required: true,
    },
  },
  data() {
    // the top all caps properties are not in alpha order, and that's OK
    // they're to help organize the data arrays and the intention is to group them here
    return {
      ROLE_INDEX: 0,
      SIGNER_NAME_INDEX: 1,
      EMAIL_ADDRESS_INDEX: 2,
      SIGNER_FIELD_BOOL_INDEX: 3,
      EXTERNAL_USER_BOOL_INDEX: 4,
      EXTERNAL_USER_NAME_INDEX: 5,
      EXTERNAL_USER_EMAIL_INDEX: 6,
      USER_IS_BLANK: 7,
      userList: [],
    }
  },
  computed: {
    pairedDownView() { return this.$route.params.pairedDownView },
  },
  async created() {
    this.userList = await this.$store.dispatch('security/getUserListEnabledOnly')
    this.userList = this.userList.filter(user => {
      return user.Email !== ''
    })
    this.updateReadyToSend()
  },
  methods: {
    checkboxClicked(row, type) {
      this.$emit("checkbox-clicked", row, type)
      this.updateReadyToSend()
    },
    //return indeces and users to insert into the table
    formatForSave() {
      let indeces = []
      let users = []
      for (let i = 0; i < this.tableData.Rows.length; i++) {
        let userID = this.tableData.Rows[i][1]
        if (typeof userID === 'number'){
          let user = this.userList.find(element => element.UserId === userID)
          indeces.push(i)
          users.push(user)
        }
      }
      return {indeces: indeces, users: users}
    },
    moveRow(row1Index, row2Index) {
      this.$emit("move-rows", row1Index, row2Index)
      this.updateReadyToSend()
    },
    uncheckWhenDisabled(row, index) {
      this.$emit("uncheck-when-disabled", row, index)
      this.updateReadyToSend()
    },
    updateReadyToSend() {
      this.$forceUpdate()
      this.$emit('readyToSend')
    },
    updateRoleFromDropList(options){
      this.$emit('role-droplist-change', options)
      this.updateReadyToSend()
    },
    deleteRow(row) {
      this.$emit("delete-row", row)
      this.updateReadyToSend()
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .header-row {
    background-color: $grey-100;
  }

  .table-data {
    padding: 10px 0 10px 15px;
  }

  .table-header {
    label {
      padding-left: 16px;
    }
  }
  .vasion-html-table-default table td > div {
    padding: 0px;
  }

  .grey-background {
    background-color: $grey-50;
  }

  .reorder-arrow {
    margin: 12px;
  }

  .td-set-width {
    max-width: inherit;
    width: inherit !important;
  }

  #reordering-arrows {
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
</style>
